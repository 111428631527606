/* General Dark Mode Styles */
body {
  background-color: #121212; /* Dark background */
  color: #ffffff; /* Light text */
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

h1 {
  text-align: center;
  color: #90caf9; /* Light blue */
  margin-top: 20px;
}

p {
  text-align: center;
  margin-bottom: 20px;
  color: #90caf9;
}

/* Styling the ul and li */
ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

li {
  margin: 10px 0;
  padding-bottom: 10px;
}

a {
  text-decoration: none;
  color: #64b5f6; /* Light blue for links */
  font-weight: bold;
  padding: 10px 20px;
  
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

a:hover {
  background-color: #64b5f6; /* Change background on hover */
  color: #121212; /* Change text color on hover */
}

button {
  background-color: #64b5f6;
  color: #121212;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  
}

button:hover {
  background-color: #90caf9; /* Lighter blue */
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

#waste{
  color: #000;
}

#red{
  color:red;
}
#black{
  color:#000;
}

#about{
  text-align: center;
  margin-bottom: 20px;
  color: #000;
  font-size:1.5rem;
  font-weight: bold;
}

#about-block{
  width:67%;
  margin: 0 auto;
}

/* For smaller screens (mobile-first design) */
@media (max-width: 768px) {
  body {
    font-size: 14px;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  table {
    width: 100%;
    font-size: 12px;
  }
  #about{
    font-size: 1rem;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
}

.item {
  flex: 1 1 calc(50% - 20px); /* Adjust size */
  margin: 10px;
  box-sizing: border-box;
}
.search-bar {
  padding: 12px 15px; /* Internal spacing */
  margin: 20px 0;
  width: 100%; /* Full width by default */
  max-width: 400px; /* Restrict max width on larger screens */
  border-radius: 20px;
  border: 1px solid #ccc;
  outline: none;
  font-size: 16px;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9;
  transition: all 0.3s ease; /* Smooth resizing */
}



/* For larger screens */
@media (min-width: 769px) {
  body {
    font-size: 16px;
  }

  table {
    width: 80%;
  }
}

@media (max-width: 620px){
  #header-title{
    display: none;
  }
  #about{
    font-size: 1rem;
  }
}
