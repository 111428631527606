div {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;
}

#h1-header{
  justify-content: center;
}

.about{
  color:#000;
}